<template>
   <el-dialog
        v-model="isShow"
        title="编辑节点模版"
        width="30%"
        :before-close="handleClose"
   >
     <el-form ref="formRef" :inline="false" :model="formState" :rules="rules">
       <el-form-item label="模版名称" prop="name">
         <el-input v-model="formState.name" maxlength="10" placeholder="请输入模版名称"/>
       </el-form-item>
       <el-form-item label="项目类型" prop="projectTypeId">
         <el-select v-model="formState.projectTypeId" style="width:100%" filterable clearable
                    placeholder="请选择项目类型">
           <el-option
               v-for="item in productTypeOptions"
               :key="item.id"
               :label="item.label"
               :value="item.id"
           />
         </el-select>
       </el-form-item>
     </el-form>
     <template #footer>
          <span class="dialog-footer">
            <el-button @click="handleClose">取消</el-button>
            <el-button type="primary" @click="handleSubmit" :disabled="submitting">
              确定
            </el-button>
          </span>
     </template>
  </el-dialog>
</template>

<script>
import {findNodeTemplateForm, getNodeTemplate, updateNodeTemplate} from '@/api/node_template'
import { isResOK } from '@/api/response'
import {getProjectTypeOptions} from "@/api/project_type";

export default {
  name: 'EditNodeTemplate',
  props: {
    visible: {
      type: Boolean,
      required: true,
    },

    id: {
      type: Number,
      required: true,
    },
  },

  emits: ['update:visible', 'completed'],

  data() {
    return {
      formState: {},

      submitting: false,
      rules: {
        name: [
          {required: true, message: "模版名称不能为空", trigger: "blur"}
        ],
        projectTypeId: [
          {required: true, message: "项目类型不能为空", trigger: "blur"}
        ],
      },
      productTypeOptions: [],
      loading: true,
    }
  },

  computed: {
    isShow: {
      get() {
        return this.visible
      },

      set(val) {
        this.$emit('update:visible', val)
      },
    },
  },

  created() {
    this.getProductTypeOptions()
    this.fetchFormData()
  },

  methods: {
    fetchFormData() {
      this.loading = true
      getNodeTemplate(this.id, {}).then((res) => {
        if (isResOK(res)) {
          this.formState = res.data
        }
      }).finally(() => {
        this.loading = false
      })
    },

    getProductTypeOptions() {
      this.productTypeOptions = []
      getProjectTypeOptions().then(res => {
        if (isResOK(res)) {
          this.productTypeOptions = res.data
        }
      })
    },

    handleSubmit() {
      if (this.submitting) {
        this.$message({
                  message: '已经提交，无需重复提交',
                  type: 'warning',
                })
        return
      }

      this.submitting = true
      this.$refs.formRef.validate((valid, fields) => {
        if (valid) {
          this.formState.id = this.id
          updateNodeTemplate(Object.assign({}, this.formState, { operatingMode: 'web' })).then((res) => {
            if (isResOK(res)) {
              // 关闭模态框
              this.isShow = false
              // 告知父组件已完成
              this.$emit('completed')
            }
          }).finally(() => {
            this.submitting = false
          })
        } else {
          this.submitting = false
        }
      }).catch(() => {
        console.log('111111')
        this.submitting = false
      })
    },

    handleClose(){
      this.isShow = false
    }
  },
}
</script>
